<template>
  <TableRow class="user-table-row rates-table">
    <template #content>
      <Accordion>
        <template #heading>
          <div
            class="d-flex align-items-center justify-content-between accordion-header"
          >
            <TableCell size="xl">
              <Country id="usa" name="United States" />
            </TableCell>
            <TableCell size="xs">
              <Status :status="true" />
            </TableCell>
            <TableCell size="xs"> <Status :status="true" /> </TableCell>
            <TableCell size="xs"> <Status :status="true" /> </TableCell>
            <TableCell size="xs"> <Status status="danger" /> </TableCell>
            <TableCell size="lg">
              <strong>14 May 2020 at 1:54 am</strong>
            </TableCell>
            <TableCell size="md">
              <UserInfo
                :avatar="require('@/assets/img/sophie.png')"
                name="Úrsula Corberó"
              />
            </TableCell>
          </div>
        </template>
        <template #body>
          <div class="table-accordion-body pb-5 pt-4">
            <div class="row mb-1">
              <div class="col-12">
                <Card :title="null" class="px-0">
                  <template #tabs>
                    <tabs v-model="selectedTab" class="padding-inner pb-0 pt-3">
                      <tab
                        v-for="(tab, i) in tabs"
                        :key="`t${i}`"
                        class="tab "
                        :val="tab"
                        :label="tab"
                        :indicator="true"
                      />
                    </tabs>
                  </template>

                  <template #tabs-content>
                    <tab-panels v-model="selectedTab" :animate="false">
                      <tab-panel :val="tabs[0]" class="card-padding-x">
                        <div class="w-100">
                          <div class="pt-5 ">
                            <h2 class="mb-4">Call Rates</h2>
                          </div>

                          <div class="mt-4">
                            <TableView
                              class="accordion-table no-shadow height-auto pb-4"
                            >
                              <template #table-head>
                                <TableHeadCell size="sm" name="Code" />
                                <TableHeadCell size="lg" name="Area" sort="a" />
                                <TableHeadCell
                                  size="md"
                                  name="Multitel rates"
                                  sort="a"
                                />

                                <TableHeadCell
                                  size="xl"
                                  name="Telesim fee, %"
                                />

                                <TableHeadCell
                                  size="md"
                                  name="Telesim Total"
                                  sort="a"
                                  class="centered"
                                />
                              </template>

                              <template #table-body>
                                <TableRow
                                  v-for="n of 10"
                                  :key="n"
                                  :class="{
                                    'mb-3': n === 10
                                  }"
                                >
                                  <template #content>
                                    <TableCell size="sm">+972151</TableCell>
                                    <TableCell size="lg">
                                      Israel Mobile
                                    </TableCell>
                                    <TableCell size="md">$0.0288</TableCell>
                                    <TableCell size="xl">
                                      <div v-if="n === 1" class="fee-select">
                                        <SelectInput
                                          :options="feeSelect"
                                          size="sm"
                                          @select="feeSelectHandler"
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell size="md">
                                      <strong
                                        class="color-primary text-center xl"
                                      >
                                        <b>
                                          $0.0288
                                        </b>
                                      </strong>
                                    </TableCell>
                                  </template>
                                </TableRow>
                              </template>
                            </TableView>
                          </div>
                        </div>
                      </tab-panel>
                    </tab-panels>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </template>
      </Accordion>
    </template>
  </TableRow>
</template>

<script>
import TableRow from "@/components/Table/TableRow";
import Accordion from "@/components/ui/Accourdion/Accordion";
import TableCell from "@/components/Table/TableCell";
import UserInfo from "@/components/UserInfo/UserInfo";
import Card from "@/components/ui/Card/Card";
import Country from "@/components/ui/Country/Country";
import Status from "@/components/ui/Status";
import TableView from "@/components/Table/TableView";

import TableHeadCell from "@/components/Table/TableHeadCell";
import SelectInput from "@/components/Form/SelectInput";
const tabs = ["Calls", "Calls anonymous", "SMS", "MMS", "FAX", "FAX anonymous"];

export default {
  name: "RatesTableRow",
  components: {
    SelectInput,
    TableHeadCell,
    Status,
    Country,
    Card,
    UserInfo,
    TableCell,
    Accordion,
    TableRow,
    TableView
  },
  props: {},
  data() {
    return {
      tabs,
      selectedTab: tabs[0],
      feeSelect: [
        {
          id: 1,
          name: "10%",
          selected: true
        },
        {
          id: 2,
          name: "15%",
          selected: false
        },
        {
          id: 3,
          name: "20%",
          selected: false
        }
      ]
    };
  },

  methods: {
    feeSelectHandler(id) {
      this.feeSelect.forEach(f => {
        f.selected = f.id === id;
      });
    }
  }
};
</script>

<style lang="scss">
.rates-table {
  .pseudo-table-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: max-content;
    border: none;
    &:before {
      display: none;
    }
  }

  .pseudo-table--head {
    border-bottom: none;
    position: relative;
    margin-bottom: 10px;
    padding-right: 0 !important;
    overflow: visible;
    &:before {
      content: "";
      width: calc(100% + #{$card-padding-x * 2});
      height: 2px;

      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      background-color: $border-color;
      z-index: 999;
    }
  }

  .pseudo-table--body {
    .pseudo-table-row {
      padding: 0.5em 0;
      .fee-select {
        height: 100%;
        min-height: 30px;
        position: relative;
        & > * {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
